/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import store from '../store';

const snackbar = (message) => {
  store.dispatch('app/snackbar', message);
};

const AlertsPlugin = {
  install(Vue, options) {
    Vue.prototype.$info = (message, timeout) => {
      snackbar({
        text: message,
        color: 'info',
        timeout,
      });
    };

    Vue.prototype.$success = (message, timeout) => {
      snackbar({
        text: message,
        color: 'success',
        timeout,
      });
    };

    Vue.prototype.$error = (e, fallbackMessage, timeout) => {
      // 500 Internal Server Error
      // 400 Bad Request
      // 412 Precondition failed
      // 422 Unprocessable entity
      // 423 Locked
      // default message
      let errorMessage = 'Ocurrió un problema. Intenta nuevamente.';
      if ([400, 412, 422, 423].includes(e?.response?.status)) {
        const errors = e.response.data?.errors ?? e.response.data?.innerErrors;
        // errors or innerErrors is an object of fields with validation errors;
        // each field have an array of error messages
        // show the first message of the first field
        if (errors) {
          // eslint-disable-next-line prefer-destructuring
          errorMessage = errors[Object.keys(errors)[0]][0];
        } else {
          errorMessage = e?.response?.data?.error
            ?? fallbackMessage
            ?? errorMessage;
        }
      } else if (typeof e === 'string') {
        errorMessage = e;
      } else if (typeof e === 'object') {
        errorMessage = e;
      } else if (e?.response?.status !== 500) {
        const error = e?.response?.data?.error;
        errorMessage = error
          ?? fallbackMessage
          ?? errorMessage;
      }
      if (errorMessage.toString().startsWith('Error:')) {
        errorMessage = errorMessage.toString()
          .replace('Error:', '')
          .trim();
      }
      snackbar({
        text: errorMessage,
        color: 'error',
        timeout,
      });
    };
  },
};

export default AlertsPlugin;
